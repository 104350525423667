<template>
<el-dialog id="create-new-opportunity" :visible.sync="dialogVisible" width="800" :show-close="false">
    <el-card>
        <DialogHeader title="Create a New Rental Opportunity" @close="dialogVisible = false"></DialogHeader>
        <div class="form">
            <el-form ref="newRentalForm" :model="formData">
                <div class="form-item">
                    <div class="label text-left">Email Address</div>
                    <div class="d-flex align-center border-bottom">
                        <div>
                            <img src="../../../assets/icon/sms.svg" class="pr_10 pb_12" alt="" />
                        </div>
                        <el-form-item prop="email" :rules="rule.emailValidation">
                            <el-input v-model.trim="formData.email" placeholder="Enter email address"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="assignment">
                    <div class="top d-flex justify-between">
                        <div class="heading">Assignment</div>
                        <div class="assign">Assign</div>
                    </div>
                    <p>Assigned to...</p>
                </div>
                <div class="form-item">
                    <div class="label text-left">First Name</div>
                    <div class="d-flex align-center border-bottom">
                        <div>
                            <img src="../../../assets/icon/user.svg" class="pr_10 pb_12" alt="" />
                        </div>
                        <el-form-item prop="firstName" :rules="rule.firstNameValidation">
                            <el-input v-model="formData.firstName" placeholder="Enter first name"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-item">
                    <div class="label text-left">Last Name</div>
                    <div class="d-flex align-center border-bottom">
                        <div>
                            <img src="../../../assets/icon/user.svg" class="pr_10 pb_12" alt="" />
                        </div>
                        <el-form-item prop="lastName" :rules="rule.lastNameValidation">
                            <el-input v-model="formData.lastName" placeholder="Enter last name"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-item">
                    <div class="label text-left">Mobile Phone</div>
                    <div class="d-flex align-center border-bottom">
                        <div>
                            <img src="../../../assets/icon/call.svg" class="pr_10 pb_12" alt="" />
                        </div>
                        <el-form-item prop="phone">
                            <el-input v-model="formData.phone" placeholder="Enter phone number"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-item" data-app>
                    <div class="label text-left">Date Range</div>
                    <el-date-picker v-model="formData.dateRange" class="date-picker" type="daterange" start-placeholder="Start date" end-placeholder="End date">
                        <template slot="prepend">
                            <img src="../../../assets/icon/calendar.svg" alt="" />
                        </template>
                    </el-date-picker>
                </div>
                <div class="form-item">
                    <div class="label text-left">Property Address</div>
                    <div class="d-flex align-center border-bottom">
                        <div>
                            <img src="../../../assets/icon/location.svg" class="pr_10 pb_12" alt="" />
                        </div>
                        <el-form-item prop="propertyAddress">
                            <el-input v-model="formData.propertyAddress" placeholder="Street address"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="note-section text-left">
                    <h4>Add a note</h4>
                    <p>Including details here might help the agent.</p>
                    <textarea v-model="formData.note"></textarea>
                </div>
                <div class="dialog-footer d-flex justify-flex-end">
                    <DialogCancelButton class="mr-5" :isDisabled="formLoading" @cancel="dialogVisible = false"></DialogCancelButton>
                    <DialogCreateCLoseLeadButton isCreate :isDisabled="formLoading" @click="handleCreateOpportunity"></DialogCreateCLoseLeadButton>
                </div>
            </el-form>
        </div>
    </el-card>
</el-dialog>
</template>

<script>
import dayjs from "dayjs";
import {
    apiCreateRentalOpportunity
} from "../../../API/api";
export default {
    components: {
        DialogCancelButton: () => import("../../Buttons/DialogCancel.vue"),
        DialogCreateCLoseLeadButton: () =>
            import("../../Buttons/DialogCreateCloseLead.vue"),
        DialogHeader: () => import("../../Dialog/DialogHeader.vue"),
    },
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialogVisible: this.showDialog,
            menu: false,
            formLoading: false,
            formData: {
                email: "",
                firstName: "",
                lastName: "",
                phone: "",
                propertyAddress: "",
                note: "",
                dateRange: [],
            },
            errors: {},
            rule : {
                emailValidation : [ { required: true, message: 'Email is required', trigger: 'blur' }, { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] } ],
                firstNameValidation : [{ required: true, message: 'First Name is required', trigger: ['blur', 'change'] }],
                lastNameValidation : [{ required: true, message: 'Last Name is required', trigger: ['blur', 'change'] }],
            }
        };
    },
    watch: {
        dialogVisible(nv) {
            if (!nv) {
                this.$emit("update:showDialog", false);
            }
        },
    },
    methods: {
        async handleCreateOpportunity() {
            this.$refs['newRentalForm'].validate((valid) => {
                if (valid) {
                    const {
                        email,
                        firstName: first_name,
                        lastName: last_name,
                        propertyAddress: property_address,
                        phone,
                        dateRange,
                        note,
                    } = this.formData;
                    const payload = {
                        email,
                        first_name,
                        last_name,
                        property_address,
                        phone,
                        note,
                        arrival_date: dateRange[0] ?
                            dayjs(dateRange[0]).format("YYYY-MM-DD") : null,
                        departure_date: dateRange[1] ?
                            dayjs(dateRange[1]).format("YYYY-MM-DD") : null,
                    };
                    try {
                        this.formLoading = true;
                        const response = apiCreateRentalOpportunity(payload);
                        this.$message.success("New Rental Opportunity created successfully.");
                        this.$emit("success", response.id);
                        this.dialogVisible = false;
                    } catch (error) {
                        this.errors = error.response.data;
                        if (
                            error &&
                            error.response &&
                            error.response.data.hasOwnProperty("detail")
                        ) {
                            this.$message.error(error.response.data.detail);
                        }
                    } finally {
                        this.formLoading = false;
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
    min-height: 50vh;
    max-height: 80vh !important;
    overflow: auto;
    padding: 0px 20px 30px 20px;
}

/deep/ .el-dialog__header {
    padding: 0px;
}

/deep/ .el-card {
    box-shadow: none !important;
    border: none !important;
}

/deep/ .el-input__inner {
    border-style: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #767676 !important;
    font-size: 16px !important;
    padding: 7px 0px 0px 0px !important;
}

/deep/ .el-form-item {
    width: 100% !important;
}

/deep/ .el-dialog {
    width: 783px !important;
    border-radius: 4px !important;
    margin-top: 10vh !important;

    .note-section {
        h4 {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            margin: 5px !important;
            line-height: 24px;
            color: #1c4a5e;
        }

        p {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #43555a;
        }

        textarea {
            border: 1px solid #767676;            
            width: 95%;
            border-radius: 5px;
            height: 100px;            
            font-size: 18px;
            padding: 15px;
            margin-bottom: 40px;
        }
    }

    .assignment {
        display: none;
        border: 1px solid #d8e2e4;
        border-radius: 8px;
        padding: 20px;

        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #4c737f;

        .heading {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: #092a35;
        }

        .assign {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            color: #1c4a5e;
            text-decoration: underline;
        }

        .top {
            display: flex;
            justify-content: space-between;
        }
    }

    .form-item {
        margin: 10px 0px 0px 0px;
        width: 85%;

        .date-picker {
            width: 100%;
        }

        .label {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #43555a;
            margin-bottom: 8px;
            text-align: left !important;

            /deep/ input {
                padding: 0px;
            }

            /deep/ img {
                margin-top: -5px;
            }

            .vdp-datepicker input {
                padding: 0px 10px;
                border-bottom: 1px solid #9da6a8;
                font-size: 14px;
            }
        }
    }
}
</style>
